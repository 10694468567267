import React from 'react'
import Layout from '../../components/layout'
import SEO from '../../components/seo'
import Img from './about-image'
import styled from 'styled-components'

const AboutPage = ({ location }) => {
  const seoTitle = 'About House of Iman Sufi Qawwali Group'
  const aboutFirstWords = 'House of Iman'
  const aboutFirstParagraph = ` is a Sufi Music Group based on Long Island, New York. We are deeply passionate about the music we are honored to learn, create, & perform. Our path is one of finding our personal truth through exploration of the great masters before us, not only in music but all art, philosophy, dance, poetry, and other mediums that bring out one's passion.`
  const aboutSecondParagraph = `We have been fortunate to study a wide variety of music including Indian classical, Qawwali, Ghazal, & modern popular music. We hope you enjoy our work, please reach out to connect, we love to find others along the path who are exploring their own truth.`
  const missionFirstWords = `Our mission`
  const mission =
    ' is to perform inspiring music & create gripping narrative that is rooted in the core of our Sufi mystical tradition. We are telling our story and that of the universal experience towards inner realization through music, writing, poetry, dance, & story-telling. We hope to spark the desire in others to find their deep inner purpose & live lives in alignment with that truth.'

  return (
    <Layout location={location}>
      <SEO title={seoTitle} />
      <AboutHeader>About Us</AboutHeader>
      <SummaryText>
        <b>{aboutFirstWords}</b>
        {aboutFirstParagraph}
        <p>{aboutSecondParagraph}</p>
      </SummaryText>
      <AboutHeader>Our Mission</AboutHeader>
      <SummaryText>
        <b>{missionFirstWords}</b>
        {mission}
      </SummaryText>
      <ImageContainer>
        <Image />
      </ImageContainer>
    </Layout>
  )
}

export default AboutPage

const SummaryText = styled.div`
  text-align: justify;
  font-size: ${(props) => props.theme.pageText};
  white-space: pre-wrap;
`

const AboutHeader = styled.div`
  font-size: ${(props) => props.theme.headerText};
  text-align: center;
  margin: 0% 10% 5%;
  border-bottom: 2px solid;
  border-bottom-color: ${(props) => props.theme.gray};
  line-height: 1.3;
  color: ${(props) => props.theme.gray};
  font-variant: small-caps;
`

const Image = styled(Img)`
  max-height: inherit;
  transition: all 0.5s ease;
`

const ImageContainer = styled.div`
  max-height: 600px;
  margin: 20px 0px;
  overflow: hidden;
  border-radius: 15px;
  position: relative;
`
